import React from 'react';
import './BottomFooter.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faFacebookF } from '@fortawesome/free-brands-svg-icons';

import { useTranslation } from 'react-i18next';


const BottomFooter = () => {
    const { t } = useTranslation();

    return (
        <footer>
            <div className="container">
                <div className="row pt-r">
                    <div className="col-lg-6 col-xs-12 left-row">
                        <div className="left-text-content">
                            <p className="copy-right-text"> {t('op.bottom-footer.copyright')} </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xs-12 right-row">
                        <div className="right-text-content">
                            <ul className="social-icons">
                                <li><p> {t('op.bottom-footer.links-label')} </p></li>
                                <li><a href="https://www.facebook.com/share/r/36fJYutvosmasBHf/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                                <li><a href="https://www.linkedin.com/company/zonezapp-tech-ltd/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default BottomFooter;