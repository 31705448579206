import React, { useEffect, useRef } from 'react';
import './Marketing.css';
import Mic from '@mui/icons-material/Mic';
import Bolt from '@mui/icons-material/Bolt';
import Forum from '@mui/icons-material/Forum';
import Video from '../../assets/video/cover-video.mp4';
import MarketingIMG from '../../assets/backgrounds/marketing-bg-2.png';

import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

const Marketing = () => {
    // Refs for each element to be animated
    const marketingSectionRef = useRef(null);
    const headingRef = useRef(null);
    const textRef = useRef(null);
    const voiceRef = useRef(null);
    const updatesRef = useRef(null);
    const communityRef = useRef(null);
    const videoRef = useRef(null);
    const { t } = useTranslation();


    useEffect(() => {
        const options = {
            root: null, // viewport
            threshold: 0.2, // 20% of the section should be visible
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && entry.target.id === 'marketing') {
                    // Trigger animations when the marketing section is visible
                    if (headingRef.current) {
                        headingRef.current.classList.add('animate__animated', 'animate__fadeIn', 'animate__delay-2s');
                    }
                    if (textRef.current) {
                        textRef.current.classList.add('animate__animated', 'animate__fadeIn');
                    }
                    if (voiceRef.current) {
                        voiceRef.current.classList.add('animate__animated', 'animate__fadeInLeft');
                    }
                    if (updatesRef.current) {
                        updatesRef.current.classList.add('animate__animated', 'animate__fadeInRight');
                    }
                    if (communityRef.current) {
                        communityRef.current.classList.add('animate__animated', 'animate__fadeInLeft');
                    }
                    if (videoRef.current) {
                        videoRef.current.classList.add('animate__animated', 'animate__fadeInTopRight', 'animate__delay-2s');
                    }
                }
            });
        }, options);

        // Observe the entire marketing section
        if (marketingSectionRef.current) {
            observer.observe(marketingSectionRef.current);
        }

        // Cleanup observer when the component unmounts
        return () => {
            if (marketingSectionRef.current) observer.unobserve(marketingSectionRef.current);
        };
    }, []);

    return (
        <section id="marketing" ref={marketingSectionRef} className='marketing-wrapper'>

            <div className="container">
                <div className="row featurette">
                    <div className="col-md-7">
                        <h2 ref={headingRef} className="featurette-heading">
                            <Trans i18nKey="op.marketing.header">
                                Why <span className="text-highlight">ZoneZapp</span>?
                            </Trans>
                        </h2>
                        <p ref={textRef} className="text">
                            {t('op.marketing.sub-text')}
                        </p>

                        <div className="row row-cols-1 g-4 py-5">
                            <div ref={voiceRef} className="col d-flex justify-content-start">
                                <div className="icon-square glassmorphism flex-shrink-0 me-3">
                                    <Mic className="bi" width="1.25em" height="1.25em" />
                                </div>
                                <div className='start-content'>
                                    <h4 className="mb-0"> {t('op.marketing.marketing-items.marketing-1.heading')} </h4>
                                    <p>
                                        <Trans i18nKey="op.marketing.marketing-items.marketing-1.description">
                                            Hear from real people in your community,<br /> sharing their honest perspectives.
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                            <div ref={updatesRef} className="col d-flex justify-content-start">
                                <div className="icon-square glassmorphism flex-shrink-0 me-3">
                                    <Bolt className="bi" width="1.25em" height="1.25em" />
                                </div>
                                <div className='start-content'>
                                    <h4 className="mb-0"> {t('op.marketing.marketing-items.marketing-2.heading')} </h4>
                                    <p>
                                        <Trans i18nKey="op.marketing.marketing-items.marketing-2.description">
                                            Get the latest news and events as they happen,<br /> from those who are right there.
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                            <div ref={communityRef} className="col d-flex justify-content-start">
                                <div className="icon-square glassmorphism flex-shrink-0 me-3">
                                    <Forum className="bi" width="1.25em" height="1.25em" />
                                </div>
                                <div className='start-content'>
                                    <h4 className="mb-0"> {t('op.marketing.marketing-items.marketing-3.heading')} </h4>
                                    <p>
                                        <Trans i18nKey="op.marketing.marketing-items.marketing-3.description">
                                            Engage in meaningful discussions and build a stronger,<br /> more informed community.
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-5">
                        <div ref={videoRef} className="marketing-image-container">
                            <video autoPlay muted loop className="marketing-image-one">
                                <source src={Video} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <img className="marketing-image-two" src={MarketingIMG} alt="marketing-sketch" />
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default Marketing;