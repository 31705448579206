import React, { useState, useRef, useEffect } from 'react';
import './Contact.css';
import { Call, Email, Public } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: ''
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  // Refs for elements to animate
  const contactSectionRef = useRef(null);
  const headingRef = useRef(null);
  const subheadingRef = useRef(null);
  const emailRef = useRef(null);
  const websiteRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.target.id === 'contact-us') {
          // Apply animations when elements come into view
          if (headingRef.current) {
            headingRef.current.classList.add('animate__animated', 'animate__fadeInLeft', 'animate__delay-2s');
          }
          if (subheadingRef.current) {
            subheadingRef.current.classList.add('animate__animated', 'animate__fadeInLeft', 'animate__delay-3s');
          }
          if (emailRef.current) {
            emailRef.current.classList.add('animate__animated', 'animate__fadeInLeft', 'animate__delay-4s');
          }
          if (websiteRef.current) {
            websiteRef.current.classList.add('animate__animated', 'animate__fadeInLeft', 'animate__delay-5s');
          }
          if (buttonRef.current) {
            buttonRef.current.classList.add('animate__animated', 'animate__fadeInRight', 'animate__delay-5s');
          }
        }
      });
    }, options);

    if (contactSectionRef.current) {
      observer.observe(contactSectionRef.current);
    }

    return () => {
      if (contactSectionRef.current) observer.unobserve(contactSectionRef.current);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch('https://alpha.zonezapp.com/api/contact-us', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const result = await response.json();
      if (response.ok) {
        setResponseMessage('Your message has been sent successfully!');
        setErrorMessage('');
        setFormData({ name: '', phone: '', email: '', subject: '', message: '' });
        setTimeout(() => {
          setResponseMessage('');
        }, 5000);
      } else {
        setErrorMessage(result.error || 'Failed to send message.');
        setResponseMessage('');
        setTimeout(() => {
          setErrorMessage('');
        }, 5000);
      }
    } catch (error) {
      setErrorMessage('An error occurred while sending your message.');
      setResponseMessage('');
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section id="contact-us" ref={contactSectionRef} className="contact-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-xs-12">
            <div className="left-text-content">
              <div className="section-heading">
                <h6 ref={headingRef} className="text-start">{t('op.contact.header')}</h6>
                <h2 ref={subheadingRef} className="text-start">{t('op.contact.sub-text')}</h2>
              </div>
              <ul className="contact-info">
                <li>
                  <div ref={emailRef} className="col d-flex align-items-center">
                    <div className="icon-square glassmorphism flex-shrink-0 me-3">
                      <Email className="bi" width="1em" height="1em" />
                    </div>
                    <div>
                      <a href="mailto:customerservice@zonezapp.com" className="mail-link">
                        customerservice@zonezapp.com
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div ref={websiteRef} className="col d-flex align-items-center">
                    <div className="icon-square glassmorphism flex-shrink-0 me-3">
                      <Public className="bi" width="1em" height="1em" />
                    </div>
                    <div>
                      <a href="https://online.zonezapp.com/" className="company-link">
                        online.zonezapp.com
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-xs-12">
            <div className="contact-form">
              <form id="contact" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <fieldset>
                      <input 
                        name="name" 
                        type="text" 
                        id="name" 
                        placeholder={t('op.contact.placeholders.name')}
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </fieldset>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <fieldset>
                      <input 
                        name="phone" 
                        type="text" 
                        id="phone" 
                        placeholder={t('op.contact.placeholders.phone')} 
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </fieldset>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <fieldset>
                      <input 
                        name="email" 
                        type="email" 
                        id="email" 
                        placeholder={t('op.contact.placeholders.email')} 
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </fieldset>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <fieldset>
                      <input 
                        name="subject" 
                        type="text" 
                        id="subject" 
                        placeholder={t('op.contact.placeholders.subject')}  
                        value={formData.subject}
                        onChange={handleChange}
                      />
                    </fieldset>
                  </div>
                  <div className="col-lg-12">
                    <fieldset>
                      <textarea 
                        name="message" 
                        rows="6" 
                        id="message" 
                        placeholder={t('op.contact.placeholders.message')} 
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </fieldset>
                  </div>
                  <div className="col-lg-12">
                    <fieldset>
                      <button 
                        ref={buttonRef} 
                        type="submit" 
                        id="form-submit" 
                        className="main-button-icon" 
                        disabled={loading}
                      >
                        {loading ? (
                          <div className="loader"></div>
                        ) : (
                          <>
                            {t('op.contact.action-button')} <i className="fa fa-arrow-right"></i>
                          </>
                        )}
                      </button>
                    </fieldset>
                  </div>
                </div>
              </form>
              {responseMessage && <div className="alert alert-success">{responseMessage}</div>}
              {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;