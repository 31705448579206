import React from 'react';
import './styles/Zonezapp.css';
import { Link } from 'react-router-dom';
import '../parent-components/dialog-trigger/DialogTrigger.css';
import StickyNavbarOnePager from '../sticky-navbar/StickyNavbarOnePager';

import { useTranslation } from 'react-i18next';



const TermsAndConditions = () => {
    const { t } = useTranslation();

    return (
        <section id="terms-and-conditions" className='landingPage-wrapper'>

            <StickyNavbarOnePager />

            <div className='container terms-conditions-container'>
                <br />
                <h2 className='text-center'> {t('terms-&-privacy-policy.header')} </h2>
                <br />
                <div className='content-wrapper' style={{ maxHeight: '60vh', overflowY: 'auto', padding: '0 15px' }}>
                    <br />
                    <h4>{t('terms-&-privacy-policy.points.points-1.heading')}</h4>
                    <p>{t('terms-&-privacy-policy.points.points-1.sub-text')}</p>

                    <h4>{t('terms-&-privacy-policy.points.points-2.heading')}</h4>
                    <p>{t('terms-&-privacy-policy.points.points-2.sub-text')}</p>

                    <h4>{t('terms-&-privacy-policy.points.points-3.heading')}</h4>
                    <p>{t('terms-&-privacy-policy.points.points-3.sub-text-1')}</p>
                    <ul>
                        <li>{t('terms-&-privacy-policy.points.points-3.sub-text-2.bullet-1')}</li>
                        <li>{t('terms-&-privacy-policy.points.points-3.sub-text-2.bullet-2')}</li>
                        <li>{t('terms-&-privacy-policy.points.points-3.sub-text-2.bullet-3')}</li>
                        <li>{t('terms-&-privacy-policy.points.points-3.sub-text-2.bullet-4')}</li>
                        <li>{t('terms-&-privacy-policy.points.points-3.sub-text-2.bullet-5')}</li>
                    </ul>

                    <h4>{t('terms-&-privacy-policy.points.points-4.heading')}</h4>
                    <p>{t('terms-&-privacy-policy.points.points-4.sub-text')}</p>

                    <h4>{t('terms-&-privacy-policy.points.points-5.heading')}</h4>
                    <p>{t('terms-&-privacy-policy.points.points-5.sub-text')}</p>

                    <h4>{t('terms-&-privacy-policy.points.points-6.heading')}</h4>
                    <p>{t('terms-&-privacy-policy.points.points-6.sub-text')}</p>

                    <h4>{t('terms-&-privacy-policy.points.points-7.heading')}</h4>
                    <p>{t('terms-&-privacy-policy.points.points-7.sub-text')}</p>

                    <h4>{t('terms-&-privacy-policy.points.points-8.heading')}</h4>
                    <p>{t('terms-&-privacy-policy.points.points-8.sub-text')}</p>

                    <h4>{t('terms-&-privacy-policy.points.points-9.heading')}</h4>
                    <p>{t('terms-&-privacy-policy.points.points-9.sub-text')}</p>

                    <p style={{ marginTop: '20px' }}>{t('terms-&-privacy-policy.points.points-9.sub-text-2')}</p>

                    <br />

                    <div className='d-flex justify-content-center'>
                        <Link to="/zonezapp" className="back-link">{t('terms-&-privacy-policy.action-button')}</Link>
                    </div>
                </div>
                <br />
            </div>
        </section>
    );
};

export default TermsAndConditions;