import React from 'react';
import './App.css';
import FAQ from './components/zonezapp/FAQs';
import ContactUs from './components/zonezapp/ContactUs';
import ZoneZappLandingPage from './components/zonezapp/Zonezapp';
import TermsAndConditions from './components/zonezapp/Terms&Conditions';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SinglePager from './components/parent-components/single-pager/SinglePager';



const App = () => (

  <Router>
    <div className='App'>
      <Routes>
        <Route path="/" element={<SinglePager />} />
        <Route path="/faqs" element={<FAQ />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/zonezapp" element={<ZoneZappLandingPage />} />
        <Route path="/terms&conditions" element={<TermsAndConditions />} />
      </Routes>
    </div>
  </Router>


);

export default App;
