import React, { useState } from 'react';
import './TopFooter.css';
import logo from '../../logo.png';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';


const TopFooter = () => {

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const { t } = useTranslation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email) {
            try {
                // Send POST request to the provided URL
                const response = await fetch('https://alpha.zonezapp.com/api/users', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,
                        origin: 'Newsletter',
                    }),
                });

                // Check if the request was successful
                if (response.ok) {
                    const data = await response.json();
                    setEmail('');
                    setMessage(`${email}, thank you for subscribing!`);

                    // Reload the page and navigate to the #subscription section
                    window.location.href = '#subscription'; // Scrolls to the section with id="subscription"
                    window.location.reload(); // Reload the page
                } else {
                    setMessage('Something went wrong. Please try again.');
                }
            } catch (error) {
                // console.error('Error during submission:', error);
                setMessage('There was an error. Please try again later.');
            }
        } else {
            setMessage('Please enter a valid email.');
        }
    };

    return (
        <div className="layout_padding footer_section">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <div><img src={logo} width="70" height="75" /></div>
                        <p className="dolor_text">
                            {t('op.top-footer.wrappers.first')}
                        </p>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <h1 className="quick_text"> {t('op.top-footer.wrappers.second.title')} </h1>

                        <div className="chevron_arrow"><ChevronRight className='chevron_arrow_icon' />
                            <Link className='top-footer-link' to="/terms&conditions"><span className="padding-left"> {t('op.top-footer.wrappers.second.exteneded-links.terms-&-privacy-policy')} </span></Link>
                        </div>
                        <div className="chevron_arrow"><ChevronRight className='chevron_arrow_icon' />
                            <Link className='top-footer-link' to="/zonezapp"><span className="padding-left"> {t('op.top-footer.wrappers.second.exteneded-links.registeration')} </span></Link>
                        </div>
                        <div className="chevron_arrow"><ChevronRight className='chevron_arrow_icon' />
                            <Link className='top-footer-link' to="/faqs"><span className="padding-left"> {t('op.top-footer.wrappers.second.exteneded-links.faq')} </span></Link>
                        </div>

                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <h1 className="subscribe_text"> {t('op.top-footer.wrappers.third.title')} </h1>
                        <p className="ipsum_text">
                            {t('op.top-footer.wrappers.third.sub-text')}
                        </p>

                        <form onSubmit={handleSubmit}>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                name="subscribe-email"
                                id="st-email"
                                placeholder={t('op.top-footer.wrappers.third.placeholder')}
                                className="email_text"
                                required
                            />
                            <input type="submit" className="submit_text" name="subscribe-submit" value={t('op.top-footer.wrappers.third.action-button')} />
                            <label htmlFor="st-email" className="st-subscribe-message">{message}</label>
                        </form>

                    </div>
                    {/* <div className="col-sm-6 col-md-6 col-lg-3">
                    <h1 className="quick_text">Contact Us</h1>
                    <div className="map_flag"><img src="images/map-flag.png"/><span className="padding-left">London 145 United Kingdom</span></div>
                    <div className="dolor_text"><img src="images/email-icon.png"/><span className="padding-left">adsiter@gmail.com</span></div>
                    <div className="dolor_text"><img src="images/phone-icon.png"/><span className="padding-left">+12586954775</span></div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default TopFooter;