import React, { useState, useEffect } from 'react';
import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import './FloatingActionBtn.css';
import HelpOutline from '@mui/icons-material/HelpOutline';
import BackToTopButton from '../../backTotop/BackTotop';
import ChatAssistant from '../../chat-assistant/ChatAssistant';

import { useTranslation } from 'react-i18next';


const FloatingActionButton = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isAnimating, setIsAnimating] = useState(true); // State to track animation

    const toggleChatAssistant = () => {
        setIsOpen(!isOpen);
    };

    const handleScroll = () => {
        setIsVisible(window.scrollY > 100);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        setIsAnimating(false); // Remove animation when the button is clicked
    };

    return (
        <div
            className={`fab-container ${isAnimating ? 'animate__animated animate__bounce animate__faster animate__infinite' : ''}`}
        >
            <button
                className={`fab-main-button ${isOpen ? 'fab-hidden' : ''} ${isOpen ? 'rotate' : ''}`}
                onClick={toggleMenu}
            >
                {/* {isOpen ? <Close className='close-icon'  /> : <span><Add className='add-icon' />Help?</span>} */}
                <span className='helpOutline-icon-wrapper'><HelpOutline className='helpOutline-icon' /> {t('op.floating-button.label')} </span>
            </button>

            {isOpen && (
                <div className="fab-menu">
                    <ChatAssistant toggleChatAssistant={toggleChatAssistant} />
                    <BackToTopButton toggleChatAssistant={toggleChatAssistant} />
                </div>
            )}
        </div>
    );
};

export default FloatingActionButton;