import React, { useEffect, useState } from 'react';
import './StickyNavbar.css';
import logo from '../../logo.png';
import { Segment } from '@mui/icons-material';
import { HashLink as Link } from 'react-router-hash-link';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Language from '@mui/icons-material/Language';



function StickyNavbarOnePager() {
    const { t, i18n } = useTranslation();
    const [showNavbar, setShowNavbar] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null); // Manage menu state
    const [language, setLanguage] = useState('English'); // Default language
    const [activeLink, setActiveLink] = useState('#home'); // Track the active link

    const controlNavbar = () => {
        if (window.scrollY === 0) {
            setShowNavbar(true);  // Always show the navbar at the top of the page
        } else if (window.scrollY > lastScrollY) {
            // Hide the navbar when scrolling down
            setShowNavbar(false);
        } else if (window.scrollY < lastScrollY) {
            // Show the navbar when scrolling up
            setShowNavbar(true);
        }
        setLastScrollY(window.scrollY);  // Update lastScrollY to current scroll position
    };

    const handleNavLinkClick = (id) => {
        setActiveLink(id); // Update the active link state
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (lang) => {
        if (lang) setLanguage(lang); // Update language if an option is selected
        setAnchorEl(null);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        handleMenuClose(lng);
    };

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar);
        return () => {
            window.removeEventListener('scroll', controlNavbar);  // Cleanup event listener on component unmount
        };
    }, [lastScrollY]);

    return (
        <>
            <nav className={`navbar navbar-expand-lg fixed-top ${showNavbar ? 'navbar-show' : 'navbar-hide'}`}>
                <div className="container-fluid">
                    <a className="navbar-brand logo-btn" href="#">
                        <img src={logo} alt="ZoneZapp Logo" height="70" width="75" />
                        <span className='brand'>ZoneZapp</span>
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarResponsive"
                        aria-controls="navbarResponsive"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <Segment className="toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse justify-content-center" id="navbarResponsive">
                        <ul className="navbar-nav mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link smooth 
                                    to="/#home"
                                    onClick={() => handleNavLinkClick('#home')}
                                    className={`nav-link ${!activeLink === '#home' ? 'active' : ''}`}
                                >{t('op.header.sitemaps.home')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link smooth 
                                    to="/#features"
                                    onClick={() => handleNavLinkClick('#features')}
                                    className={`nav-link ${!activeLink === '#features' ? 'active' : ''}`}
                                >{t('op.header.sitemaps.features')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link smooth 
                                    to="/#about"
                                    onClick={() => handleNavLinkClick('#about')}
                                    className={`nav-link ${!activeLink === '#about' ? 'active' : ''}`}
                                >{t('op.header.sitemaps.about')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link smooth 
                                    to="/#contact"
                                    onClick={() => handleNavLinkClick('#contact')}
                                    className={`nav-link ${!activeLink === '#contact' ? 'active' : ''}`}
                                >{t('op.header.sitemaps.contact')}</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="d-flex ms-auto align-items-center">
                        <span className='icon-button-label'>Lang:</span>
                        <IconButton
                            color="inherit"
                            onClick={handleMenuClick}
                            aria-controls="language-menu"
                            aria-haspopup="true"
                            className="icon-btn"
                        >
                            <Language className='language-icon' />
                        </IconButton>
                        <Menu
                            id="language-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => handleMenuClose(null)}
                            keepMounted
                        >
                            <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
                            <MenuItem onClick={() => changeLanguage('fr')}>French</MenuItem>
                        </Menu>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default StickyNavbarOnePager;