import React from 'react';
import './styles/Zonezapp.css';
import Contact from '../contact/Contact';
import StickyNavbarOnePager from '../sticky-navbar/StickyNavbarOnePager';



const ContactUs = () => {
    return(
        <section id='contactUs' className='contactus-wrapper'>
            <StickyNavbarOnePager />
            <Contact />
        </section>
    );
};

export default ContactUs;