import React, { useState } from 'react';
import './DialogTrigger.css';
import { Link } from 'react-router-dom';

import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';



const GetInTouchForm = ({ handleToggleContent }) => {

    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        origin: 'Website'
    });
    const [popupMessage, setPopupMessage] = useState('');
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isSuccess, setIsSuccess] = useState(null);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://alpha.zonezapp.com/api/users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setIsSuccess(true); // Success
                setFormData({ name: '', email: '' });
                setPopupMessage('Registration successful!');
            } else {
                const errorData = await response.json();
                setIsSuccess(false); // Error
                // setPopupMessage(`Registration failed: ${errorData.message}`);
                setPopupMessage(`Registration failed, please try again later.`);
            }

        } catch (error) {
            setIsSuccess(false);
            setPopupMessage('An error occurred. Please try again.');
        }

        setIsPopupVisible(true);
        setTimeout(() => {
            setIsPopupVisible(false);
        }, 3000); // Hide popup after 3 seconds
    };

    return (
        <div className="form-container">
            <form className="p-4 p-md-5 border rounded-3 form-bg" onSubmit={handleSubmit}>
                <div className="form-floating mb-3">
                    <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="..."
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                    />
                    <label htmlFor="floatingInput">{t('zonezapp.inputfields-placeholders.username')}</label>
                </div>
                <div className="form-floating mb-3">
                    <input
                        type="email"
                        className="form-control"
                        id="floatingEmail"
                        placeholder="name@example.com"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                    <label htmlFor="floatingEmail">{t('zonezapp.inputfields-placeholders.email-address')}</label>
                </div>
                <button
                    className="w-100 btn btn-lg register-btn"
                    type="submit"
                    style={{ backgroundColor: '#ff6f00', color: '#ffffff' }}
                >
                    {t('zonezapp.action-button')}
                </button>
                <hr className="my-4" />
                <Trans i18nKey="zonezapp.sub-text">
                    By clicking Register Now!, you agree to the ZoneZapp <Link to="/terms&conditions" className='terms-conditions-link'>terms and conditions</Link> of use.
                </Trans>
            </form>

            {/* Popup for success/error messages #8BC34A #171717 */}
            {isPopupVisible && (
                <div
                    className="popup"
                    style={{
                        color: isSuccess ? '#155724' : '#721c24',
                        backgroundColor: isSuccess ? '#d4edda' : '#f8d7da',
                        border: isSuccess ? '1px solid #d4edda' : '1px solid #f8d7da'
                    }}
                >
                    <p className="popup-text">{popupMessage}</p>
                </div>
            )}
        </div>
    );
};

export default GetInTouchForm;