import React, { useState } from 'react';
import './styles/Zonezapp.css';
import logo from '../../logo.png';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ModelCover from '../../assets/backgrounds/my-app.png';
import '../parent-components/dialog-trigger/DialogTrigger.css';
import StickyNavbarOnePager from '../sticky-navbar/StickyNavbarOnePager';

import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';



const ZoneZappLandingPage = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        origin: 'Website'
    });

    const { t } = useTranslation();
    const [isSuccess, setIsSuccess] = useState(null);
    const [popupMessage, setPopupMessage] = useState('');
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://alpha.zonezapp.com/api/users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setIsSuccess(true); // Success
                setFormData({ name: '', email: '' });
                setPopupMessage('Registration successful!');
                navigate('/');
            } else {
                const errorData = await response.json();
                setIsSuccess(false); // Error
                setPopupMessage(`Registration failed, please try again later.`);
            }

        } catch (error) {
            setIsSuccess(false);
            setPopupMessage('An error occurred. Please try again.');
        }

        setIsPopupVisible(true);
        setTimeout(() => {
            setIsPopupVisible(false);
        }, 3000); // Hide popup after 3 seconds
    };

    return (
        <section id="zonezapp" className='landingPage-wrapper'>

            <StickyNavbarOnePager />

            <div className="container col-xxl-8 px-1 py-3">
                <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div className="col-10 col-sm-8 col-lg-6 model-cover">
                        <img src={ModelCover} className="d-block mx-lg-auto img-fluid" alt="ZoneZapp Mockups" width="900" height="700" loading="lazy"></img>
                    </div>
                    <div className="col-lg-6 text-center">
                        <img src={logo} className='zonezapp-logo mx-auto' height="150" width="155" alt="ZoneZapp Logo" />
                        <span className='zonezapp-text'>ZoneZapp</span>

                        {/* GetInTouchForm */}
                        <div className="form-container">
                            <form className="p-4 p-md-5 border rounded-3 form-bg" onSubmit={handleSubmit}>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="..."
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="floatingInput">{t('zonezapp.inputfields-placeholders.username')}</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="floatingEmail"
                                        placeholder="name@example.com"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="floatingEmail">{t('zonezapp.inputfields-placeholders.email-address')}</label>
                                </div>
                                <button
                                    className="w-100 btn btn-lg register-btn"
                                    type="submit"
                                    style={{ backgroundColor: '#ff6f00', color: '#ffffff' }}
                                >
                                    {t('zonezapp.action-button')}
                                </button>
                                <hr className="my-4" />
                                <small className="text-body-secondary">
                                    <Trans i18nKey="zonezapp.sub-text">
                                        By clicking Register Now!, you agree to the ZoneZapp <Link to="/terms&conditions" className='terms-conditions-link'>terms and conditions</Link> of use.
                                    </Trans>
                                </small>
                            </form>

                            {/* Popup for success/error messages #8BC34A #171717 */}
                            {isPopupVisible && (
                                <div
                                    className="popup"
                                    style={{
                                        color: isSuccess ? '#155724' : '#721c24',
                                        backgroundColor: isSuccess ? '#d4edda' : '#f8d7da',
                                        border: isSuccess ? '1px solid #d4edda' : '1px solid #f8d7da'
                                    }}
                                >
                                    <p className="popup-text">{popupMessage}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );


};

export default ZoneZappLandingPage;