import React, { useState } from 'react';
import './Testimonial.css';
import TestimonialIMG_1 from '../../assets/images/testimonial-1.png';
import TestimonialIMG_2 from '../../assets/images/testimonial-2.jpg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from 'react-i18next';



const Testimonial = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        vertical: false,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                }
            }
        ]
    };

    // Function to open a new tab
    const openNewTab = () => {
        toggleMenu();
        window.open('https://g.page/r/CYnyzhlmNhB6EBM/review', '_blank');
    };

    const [isAnimating, setIsAnimating] = useState(true);

    const { t } = useTranslation();

    const toggleMenu = () => {
        setIsAnimating(false);
    };

    const renderStars = () => {
        return (
            <div className="stars">
                {[...Array(5)].map((_, index) => (
                    <a key={index} href="https://g.page/r/CYnyzhlmNhB6EBM/review" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon 
                            icon={faStar} 
                            style={{ color: '#fbbc04', fontSize: '16px' }}
                            className={`${isAnimating ? 'animate__animated animate__pulse animate__fast animate__infinite' : ''}`} 
                        />
                    </a>
                ))}
            </div>
        );
    };

    return (
        <section id="testimonial" className='testimonial-wrapper'>
            <div className="testimonial">
                <div className="container">
                    <Slider {...settings}>
                        <div className="testimonial-item">
                            <img src={TestimonialIMG_1} alt="Testimonial Thumbnail" />
                            <p>
                                Nice Apps, allowing us to focus solely on what we need.
                            </p>
                            <h2>Jimmy Longele</h2>
                            {renderStars()}
                        </div>
                        <div className="testimonial-item">
                            <img src={TestimonialIMG_2} alt="Testimonial Thumbnail" />
                            <p>
                                ZoneZapp makes staying informed easy. Real stories, no filter, and directly from the people experiencing them.
                            </p>
                            <h2>Gedeon Oscar</h2>
                            {renderStars()}
                        </div>
                    </Slider>
                    
                    {/* Outline Button */}
                    <div className="button-wrapper">
                        <button className={`outline-button ${isAnimating ? 'animate__animated animate__pulse animate__fast animate__infinite' : ''}`} onClick={openNewTab}>
                            {t('op.testimonial.action-button')}
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonial;