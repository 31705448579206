import React, { useState } from 'react';
import './DialogTrigger.css';
import GetInTouchForm from './GetInTouchForm';
import StickyNavbar from '../../sticky-navbar/StickyNavbar';
import Hero from '../../hero/Hero';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { Close } from '@mui/icons-material';
import ModelCover from '../../../assets/svgs/register.svg';
import logo from '../../../logo.png';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function DialogTrigger() {
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    const [showTerms, setShowTerms] = useState(false); // To toggle between form and terms

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setShowTerms(false); // Reset to form when the modal closes
    };

    const handleToggleContent = () => {
        setShowTerms(!showTerms); // Toggle between form and terms content
    };

    return (
        <>
            <StickyNavbar handleOpenModal={handleOpenModal} />
            <Hero handleOpenModal={handleOpenModal} />

            {/* Modal for the "Get it now!" button */}
            <Dialog
                fullScreen
                open={openModal}
                onClose={handleCloseModal}
                PaperProps={{
                    style: { color: 'white', backdropFilter: 'blur(10px)', backgroundColor: 'rgba(255, 255, 255, 0.2)' }
                }}
                disableScrollLock
            >
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleCloseModal}
                    aria-label="close"
                    style={{ position: 'absolute', top: '20px', right: '40px', background: 'white', color: 'rgba(0, 0, 0, 0.85)' }}
                >
                    <Close />
                </IconButton>
                <DialogContent>
                    <div className="modal-content-wrapper" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <div className="modal-content" style={{ opacity: 0.8, backgroundColor: 'white', border: '1px solid rgba(255, 255, 255, 0.3)', color: 'black', padding: '20px', borderRadius: '8px', maxWidth: 'fit-content', width: '75%', textAlign: 'center' }}>
                            {showTerms ? (
                                <div className='terms-conditions-container'>
                                    <h2> {t('terms-&-privacy-policy.header')} </h2>
                                    <div className='content-wrapper' style={{ maxHeight: '60vh', overflowY: 'auto', padding: '0 15px' }}>
                                        <br />
                                        <h4>{t('terms-&-privacy-policy.points.points-1.heading')}</h4>
                                        <p>{t('terms-&-privacy-policy.points.points-1.sub-text')}</p>

                                        <h4>{t('terms-&-privacy-policy.points.points-2.heading')}</h4>
                                        <p>{t('terms-&-privacy-policy.points.points-2.sub-text')}</p>

                                        <h4>{t('terms-&-privacy-policy.points.points-3.heading')}</h4>
                                        <p>{t('terms-&-privacy-policy.points.points-3.sub-text-1')}</p>
                                        <ul>
                                            <li>{t('terms-&-privacy-policy.points.points-3.sub-text-2.bullet-1')}</li>
                                            <li>{t('terms-&-privacy-policy.points.points-3.sub-text-2.bullet-2')}</li>
                                            <li>{t('terms-&-privacy-policy.points.points-3.sub-text-2.bullet-3')}</li>
                                            <li>{t('terms-&-privacy-policy.points.points-3.sub-text-2.bullet-4')}</li>
                                            <li>{t('terms-&-privacy-policy.points.points-3.sub-text-2.bullet-5')}</li>
                                        </ul>

                                        <h4>{t('terms-&-privacy-policy.points.points-4.heading')}</h4>
                                        <p>{t('terms-&-privacy-policy.points.points-4.sub-text')}</p>

                                        <h4>{t('terms-&-privacy-policy.points.points-5.heading')}</h4>
                                        <p>{t('terms-&-privacy-policy.points.points-5.sub-text')}</p>

                                        <h4>{t('terms-&-privacy-policy.points.points-6.heading')}</h4>
                                        <p>{t('terms-&-privacy-policy.points.points-6.sub-text')}</p>

                                        <h4>{t('terms-&-privacy-policy.points.points-7.heading')}</h4>
                                        <p>{t('terms-&-privacy-policy.points.points-7.sub-text')}</p>

                                        <h4>{t('terms-&-privacy-policy.points.points-8.heading')}</h4>
                                        <p>{t('terms-&-privacy-policy.points.points-8.sub-text')}</p>

                                        <h4>{t('terms-&-privacy-policy.points.points-9.heading')}</h4>
                                        <p>{t('terms-&-privacy-policy.points.points-9.sub-text')}</p>

                                        <p style={{ marginTop: '20px' }}>{t('terms-&-privacy-policy.points.points-9.sub-text-2')}</p>

                                        <br />

                                        <div className='d-flex justify-content-center'>
                                            <Link to="/zonezapp" className="back-link">{t('terms-&-privacy-policy.action-button')}</Link>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="container col-xxl-8 px-1 py-3">
                                    <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                                        <div className="col-10 col-sm-8 col-lg-6 model-cover">
                                            <img src={ModelCover} className="d-block mx-lg-auto img-fluid" alt="ZoneZapp Mockups" width="900" height="700" loading="lazy"></img>
                                        </div>
                                        <div className="col-lg-6">
                                            <GetInTouchForm handleToggleContent={handleToggleContent} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default DialogTrigger;
